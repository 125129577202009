<!-- 
author: 张洪志
description: 基金公告详情
 -->
<template>
  <section class="notice">
    <div class="vw">
      <div class="title">{{title}}</div>
      <div class="date">
        <span>{{date}}</span>
        <span style="margin-left: 60px;">字号:</span>
        <span :class="['btn',{'current':fontStyle===1}]" @click="setFont(1)">大</span>
        <span :class="['btn',{'current':fontStyle===2}]" @click="setFont(2)">中</span>
        <span :class="['btn',{'current':fontStyle===3}]" @click="setFont(3)">小</span>
      </div>
      <div :class="['content',fontStyle===1? 'font-big':fontStyle===3 ? 'font-small':'']">
        <pre v-if="type=='0'">
          {{content}}
        </pre>
        <div v-if="type=='1'">
          <a :href="content">下载</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import { pubInfoDetail } from '@/api/fund.js'
  export default {
    name: 'PrivateNotice',
    props: {
      id: {
        type: [String,Number],
        required: true 
      }
    },
    data() {
      return {
        title: '',
        date: '',
        content: '',
        type:''
      }
    },
    computed: {
      fontStyle() {
        return this.$store.state.noticeFont || 2
      }
    },
    created() {
      this.getDetail()
    },
    methods: {
      /** 基金公告详情 */
      getDetail() {
        pubInfoDetail(this.id).then(result => {
          let data = result.data||{}
          this.title = data.infoTitle
          this.date = (data.infoPublDate||'').substr(0,10)
          this.type = data.type
          this.content = data.content
        })
      },
      /** 设置内容字号 */
      setFont(style) {
        this.$set(this.$store.state, 'noticeFont', style)
      }
    }
  }
</script>

<style lang="less" scoped>
  .notice{ background: #F3F4F6;}
  .title{font:22px "microsoft yahei"; padding: 50px 0 40px 0; width:80%; margin:auto; border-bottom: 1px solid #eee; text-align: center;}
  .date{font-size: 12px; color:#888; text-align: center; line-height: 100px;
    .btn{ margin:0 1em; background: #EDF1F7; padding:4px; border-radius: 4px;}
    .current{ background: #D5AB7D; color:#fff;}
  }
  .content{ line-height: 2em; font-size: 14px; color:#666; padding:0 80px 80px 80px;
    pre{white-space: pre-wrap;}
    p{text-indent: 2em;}
  }
  .font-big{font-size: 16px;}
  .font-small{font-size: 12px;}
</style>
